<template>
  <div style="flex: 1;" class="repoartWrap">
    <div class="left_block">
      <div class="title_block">
        <p>活动基本信息</p>
      </div>
      <div class="info_block">
        <div class="base_info_item_contain" v-loading="">
          <p class="base_info_item">
            <span class="base_info_item_label">活动品牌</span>
            <span>{{query.brand}}</span>
          </p>
          <p class="base_info_item">
            <span class="base_info_item_label">活动名称</span>
            <span>{{query.campaignName}}</span>
          </p>
          <p class="base_info_item">
            <span class="base_info_item_label">活动时间</span>
            <span>{{
                query.duration ? query.duration.split(",").join(" 至 ") : ""
              }}</span>
          </p>
        </div>
      </div>
    </div>
    <div style="width: 80%;">
      <div class="card_block" >
        <div class="title_block">
          <p>负面评论</p>
          <el-button
              type="text"
              size="mini"
              @click="downloadReport"
              style="color:#000"
              :disabled="
                  JSON.stringify(storedNum) === '{}' || downloadReportBtnLoading
                "
          ><svg-icon
              class-name="downloadIcon"
          ></svg-icon>
            <i
                class="el-icon-loading"
                v-show="downloadReportBtnLoading"
            ></i>
            {{
              downloadReportBtnLoading ? "负评报告导出中" : "负评报告下载"
            }}
          </el-button>
        </div>
        <div>
          <el-table
              class="report_stored_table_class"
              ref="sortTable"
              :data="tableData"
              :header-cell-style="headerStyle"
              :cell-style="cellStyle"
              stripe
              :height="tableHeight"
              :default-sort="{ prop: 'postTime', order: 'descending' }"
              @sort-change="handleSort"
          >
            <el-table-column
                label="发布日期"
                prop="releaseTimeStr"
                align="center"
                sortable
            >
              <template #default="scope">
                {{ scope.row.releaseTimeStr}}
              </template>
            </el-table-column>
            <el-table-column
                label="负评原文"
                prop="comment"
                align="left"
            >
              <template #default="scope">
                <span class="comment_span">{{ scope.row.comment}}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="评论用户"
                prop="uidName"
                align="center"
            >
              <template #default="scope">
                {{ scope.row.uidName}}
              </template>
            </el-table-column>
            <el-table-column
                label="KOL名称"
                prop="kolName"
                align="center"
            >
              <template #default="scope">
                {{ scope.row.kolName}}
              </template>
            </el-table-column>
            <el-table-column
                v-for="item in colData"
                :key="item.prop"
                :label="item.label"
                :prop="item.prop"
                :align="item.align"
                :width="item.width"
                :sortable="item.sortable"
                :fixed="item.fixed"
                :formatter="item.formatter"
            >

              <template #header>
                <div v-if="item.label === '平台'">
                  <el-select size="mini" v-model="tablePlatform">
                    <el-option
                        v-for="item in finSites"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                    >
                    </el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                label="投放标题"
                prop="articleTitle"
                align="center"
            >
              <template #default="scope">
                <a class="title_span" :href="scope.row.articleUrl" target="_blank">{{ scope.row.articleTitle}}</a>
              </template>
            </el-table-column>
            <el-table-column
                label="评论互动"
                prop="engagementSum"
                align="center"
                sortable
            >
              <template #default="scope">
                {{sliceDecimal( scope.row.engagementSum)}}
              </template>
            </el-table-column>
          </el-table>
          <div style="display:flex;justify-content:center;margin: 10px 0">
            <el-pagination
                layout="prev, pager, next"
                :current-page="page"
                :page-size="pageSize"
                :total="totalSize"
                @current-change="handlePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script setup>
import mainTitle from "@/components/common/mainTitle";
import { onMounted, ref, toRaw,watch} from "vue";
import {negativeCommentsReport,negativeCommentsPageQuery} from "@/api/campaignTrace";
import {platformToZn} from '@/utils/tools'
const tableHeight = ref(null)
onMounted(() => {
  tableHeight.value = document.body.clientHeight - 275;

  getList()
  setPlateform()
})
const handlePage = (value)=>{
  page.value = value;
  getList();
}
const colData = ref([{
  label: "平台",
  prop: "platform",
  sortable: false,
  width: 128,
  align: "center",
  fixed: false,
  formatter: (row, column, cellValue) => {
    return platformToZn(cellValue);
  },
}])
const page = ref(1)
const pageSize = ref(50)
const totalSize = ref(0)
const tablePlatform = ref('all')
import { useRoute } from "vue-router";
const {query} = useRoute()
const tableData = ref([])

const getList = () =>{
  const params = {
    campaignId:atob(query.id),
    contentId:query.contentId?atob(query.contentId):null,
    platform:tablePlatform.value,
    "pageNo": page.value,
    "pageSize": pageSize.value
  }
  negativeCommentsPageQuery(params).then(res =>{
    tableData.value = res.data.table
    totalSize.value = res.data.total
  })
}
watch(tablePlatform,()=>{
  getList()
})
import {mapGetters, useStore} from "vuex";
const $store = useStore()
const tempSites = ref([])
const finSites = ref([])
const setPlateform = (() =>{
  tempSites.value = JSON.parse(JSON.stringify(toRaw($store.getters.sites)))
  tempSites.value.unshift({
    label: "全平台",
    value: "all",
  });
  finSites.value = tempSites.value;

})
const downloadReportBtnLoading = ref(false)
const downloadReport = ()=> {
  downloadReportBtnLoading.value = true;
  let params = {
    campaignId:atob(query.id),
    contentId:query.contentId?atob(query.contentId):null,
  }
  negativeCommentsReport(params,query.campaignName);
  downloadReportBtnLoading.value = false;
}

</script>
<style scoped>
.base_info_item_contain {
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap:25px;
  .base_info_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:20px;
    .base_info_item_label {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      color:#666;
    }
  }
}
.comment_span{
  color:#409eff;
}
.title_span{
  color:#409eff;
  text-decoration: underline;
  cursor: pointer;
}
.repoartWrap{
  display: flex;
  width: 100%;
  justify-content: space-between;gap:20px;height:100%;
  .left_block {
    background: #fff;
    border-radius: 20px;
    height: 100%;
    width:20%;
    .title_block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 67px;
      border-bottom: 1px solid #EDEDED;
      padding: 0 20px;

      p {
        font-weight: 500;
        font-size: 20px;
        display: flex;
        align-items: center;
        color:#222;
      }
    }
    .info_block{
      padding: 20px 20px 0;
    }
  }
  .card_block{
    border-radius: 20px;
    background: #fff;
    padding:20px 20px 0;
    .title_block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom:20px;
      p {
        font-weight: 500;
        font-size: 20px;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>